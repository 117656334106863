<template>
  <a-dropdown placement="bottomCenter">
    <a class="ant-dropdown-link" @click.prevent>
      <div class="p-user-info">
        <a-avatar v-if="adminInfo.avatar === ''">
          <template #icon>
            <my-icon type="wapi-user" />
          </template>
        </a-avatar>
        <a-avatar v-else :src="adminInfo.avatar" />
        <span class="p-username">{{ adminInfo.username }}</span>
      </div>
    </a>
    <template #overlay>
      <a-menu>
        <a-menu-item @click="logout">
          <logout-outlined />
          <span>退出</span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { Menu, MenuItem, Avatar, Dropdown } from "ant-design-vue/es";
import { LogoutOutlined } from "@ant-design/icons-vue";

import store from "../store";
import tool from "../utils/tool";
import myIcon from "../utils/IconFont";

export default {
  name: "adminInfo",
  data() {
    return {
      adminInfo: {},
      isLogin: false,
    };
  },
  components: {
    myIcon,
    LogoutOutlined,
    AMenu: Menu,
    AMenuItem: MenuItem,
    AAvatar: Avatar,
    ADropdown: Dropdown,
  },
  created() {
    if (store.state.adminInfo.admin) {
      this.isLogin = true;
      this.adminInfo = store.state.adminInfo.admin;
      if (
        this.adminInfo.avatar === undefined ||
        this.adminInfo.avatar === null ||
        this.adminInfo.avatar === ""
      ) {
        this.adminInfo.avatar = "";
      } else {
        this.adminInfo.avatar += "?x-oss-process=style/avatar";
      }
    }
  },
  methods: {
    logout() {
      tool.logout();
      location.reload();
    },
  },
};
</script>

<style scoped>
.p-top {
  height: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.p-top-logo,
.p-top-menu {
  float: left;
}

.p-top-logo {
  margin-right: 20px;
}

.ant-dropdown-link {
  float: right;
}

.p-user-info {
  float: right;
  margin-right: 20px;
}

.p-username {
  margin-left: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
</style>
