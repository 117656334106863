<template>
  <a-menu
    style="background-color: #24303c; color: #cacaca; border-right: none"
    mode="inline"
    :inline-collapsed="collapsed"
    :selectedKeys="selectedKeys"
  >
    <a-menu-item
      v-for="item in routerMenus"
      :key="item.meta.key"
      @click="goTo(item)"
    >
      <template #icon>
        <icon-font :type="item.meta.icon" />
      </template>
      <span>{{ item.meta.title }}</span>
    </a-menu-item>

    <a-menu-item
      :key="systemMenu.meta.key"
      @click="goTo(systemMenu)"
      class="p-system-item"
      style="position: absolute; bottom: 0"
    >
      <template #icon>
        <icon-font type="wapi-control-fill" />
      </template>
      <span>{{ systemMenu.meta.title }}</span>
    </a-menu-item>
  </a-menu>
</template>

<script>
import { Menu, MenuItem } from "ant-design-vue/es";

import router from "../router";
import IconFont from "../utils/IconFont";
import eventBus from "vue3-eventbus";
export default {
  name: "MenuLayout",
  components: {
    IconFont,
    AMenu: Menu,
    AMenuItem: MenuItem,
  },
  data() {
    return {
      collapsed: false,
      routerMenus: [],
      systemMenu: {},
      selectedKeys: [],
    };
  },
  created() {
    this.getRoutes();
    this.selectedKeys = [];
    this.selectedKeys.push(this.$route.matched[1].meta.key);
  },
  mounted() {
    eventBus.on("selectKey", (val) => {
      console.log(val);
    });
  },
  methods: {
    getRoutes() {
      let that = this;
      const routes = router.getRoutes();
      that.routerMenus = [];
      that.systemMenu = {};
      for (let i = 0; i < routes.length; i++) {
        if (
          routes[i].meta.menuType === "menu" &&
          routes[i].meta.menuShow === 1
        ) {
          that.routerMenus.push(routes[i]);
        } else if (routes[i].path === "/system") {
          that.systemMenu = routes[i];
        }
      }
    },
    goTo(item) {
      this.selectedKeys = [];
      this.selectedKeys.push(item.meta.key);

      eventBus.emit("menu", item);

      if (item.children.length > 0) {
        eventBus.emit("selectedSubKeys", item.children[0].meta.key);
      }
      router.push(item.path);
    },
  },
};
</script>

<style scoped>
.ant-menu-item {
  height: 50px !important;
}
</style>
