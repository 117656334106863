<template>
  <a-layout>
    <!--    左侧菜单-->
    <a-layout-sider
      style="background-color: #24303c"
      :width="130"
      :collapsed="menuCollapsed"
      :trigger="null"
      collapsible
    >
      <div class="logo">PIGWAN</div>
      <div class="menu-collapsed">
        <menu-unfold-outlined
          v-if="menuCollapsed"
          class="trigger"
          @click="menuCollapsed = !menuCollapsed"
        />
        <menu-fold-outlined
          v-else
          class="trigger"
          @click="menuCollapsed = !menuCollapsed"
        />
      </div>
      <menu-layout></menu-layout>
    </a-layout-sider>

    <a-layout>
      <a-layout-header>
        <div class="home-menu">
          <div class="home-menu-icon" @click="goHome()">
            <IconFont type="wapi-home" />
          </div>
          <div class="home-menu-icon" @click="goWeb()">
            <IconFont type="wapi-earth" />
          </div>
          <div class="home-menu-icon">
            <IconFont type="wapi-menu" />
          </div>
        </div>
        <admin-info style="display: inline-block; float: right"></admin-info>
      </a-layout-header>

      <a-layout-content>
        <a-layout>
          <!--          左侧二级菜单-->
          <a-layout-sider
            style="background-color: #ffffff"
            :width="subMenuWidth"
            :collapsed="subMenuCollapsed"
            :trigger="null"
            collapsible
          >
            <div
              class="subMenuCollapsibleBtnOpen subMenuCollapsibleBtn"
              @click="changeSubMenu"
              v-if="subCollapsedStatus === 1"
              :style="{ display: displayStatus }"
            >
              <IconFont type="wapi-outdent" />
            </div>
            <div
              class="subMenuCollapsibleBtnClose subMenuCollapsibleBtn"
              @click="changeSubMenu"
              v-else
              :style="{ display: displayStatus }"
            >
              <IconFont type="wapi-indent" />
            </div>
            <sub-menu-layout></sub-menu-layout>
          </a-layout-sider>

          <a-layout-content style="width: 100%; position: relative">
            <a-breadcrumb style="margin: 20px; font-size: 12px">
              <a-breadcrumb-item
                style="padding-left: 10px; border-left: 2px solid #1890ff"
              >
                <span>当前位置：</span>
              </a-breadcrumb-item>
              <a-breadcrumb-item
                v-for="item in breadcrumb"
                :key="item.meta.key"
              >
                <router-link :to="item.path">{{ item.meta.title }}</router-link>
              </a-breadcrumb-item>
            </a-breadcrumb>
            <content-layout></content-layout>
            <a-layout-footer style="width: 100%; position: absolute; bottom: 0">
              <footer-layout></footer-layout>
            </a-layout-footer>
          </a-layout-content>
        </a-layout>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import ContentLayout from "./ContentLayout";
import FooterLayout from "./FooterLayout";
import MenuLayout from "./MenuLayout";
import SubMenuLayout from "./SubMenuLayout";

import eventBus from "vue3-eventbus";
import state from "../store/state";
import {
  Layout,
  LayoutHeader,
  LayoutSider,
  LayoutContent,
  LayoutFooter,
  Breadcrumb,
  BreadcrumbItem,
} from "ant-design-vue/es";

import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
import AdminInfo from "./AdminInfo";
import router from "../router";
import IconFont from "../utils/IconFont";

export default {
  name: "BaseLayout",
  data() {
    return {
      menuCollapsed: false,
      subMenuCollapsed: false,
      subCollapsedStatus: 0,
      selectedKeys: 1,
      subMenuWidth: 0,
      breadcrumb: [],
      displayStatus: "block",
    };
  },
  components: {
    IconFont,
    MenuLayout,
    SubMenuLayout,
    ContentLayout,
    FooterLayout,
    AdminInfo,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ALayout: Layout,
    ALayoutHeader: LayoutHeader,
    ALayoutSider: LayoutSider,
    ALayoutContent: LayoutContent,
    ALayoutFooter: LayoutFooter,
    ABreadcrumb: Breadcrumb,
    ABreadcrumbItem: BreadcrumbItem,
  },
  created() {
    this.setBreadcrumb(this.$route);
    this.getSubMenuWidth(this.$route);
    this.checkDisplayStatus(this.$route);
  },
  mounted() {
    eventBus.on("subMenuWidth", (val) => {
      this.subMenuWidth = val;
      if (this.subMenuWidth === 150) {
        this.subCollapsedStatus = 1;
      } else {
        this.subCollapsedStatus = 0;
      }
    });
  },
  updated() {
    this.setBreadcrumb(this.$route);
    this.checkDisplayStatus(this.$route);
  },
  methods: {
    checkDisplayStatus(route) {
      if (route.meta.key === "dashboard") {
        this.displayStatus = "none";
      } else {
        this.displayStatus = "block";
      }
    },
    getSubMenuWidth(route) {
      if (route.matched[1].children.length > 0) {
        this.subMenuWidth = 150;
        this.subCollapsedStatus = 1;
      } else {
        this.subMenuWidth = 0;
        this.subCollapsedStatus = 0;
      }
    },
    setBreadcrumb(route) {
      this.breadcrumb = route.matched;
    },
    goHome() {
      eventBus.emit("subMenuWidth", 0);
      eventBus.emit("selectKey", ["dashboard"]);
      router.push("/");
    },
    goWeb() {
      let url = state.adminInfo.website.domain;
      window.open("http://" + url);
    },
    changeSubMenu() {
      if (
        this.subCollapsedStatus === 0 &&
        this.$route.meta.key !== "dashboard"
      ) {
        this.subCollapsedStatus = 1;
        this.subMenuWidth = 150;
      } else {
        this.subCollapsedStatus = 0;
        this.subMenuWidth = 0;
      }
    },
  },
};
</script>

<style scoped>
.ant-layout {
  height: 100%;
}

.logo {
  height: 32px;
  margin: 16px;
  color: white;
  text-align: center;
  line-height: 32px;
}

.subMenuCollapsibleBtn {
  width: 20px;
  height: 30px;
  background-color: #f5f7f9;
  position: absolute;
  top: 40px;
  text-align: center;
  line-height: 26px;
  color: #848587;
  z-index: 999;
  border-bottom: 1px solid #c5c5c5;
  border-top: 1px solid #c5c5c5;
}
.subMenuCollapsibleBtnOpen {
  right: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-left: 1px solid #c5c5c5;
}
.subMenuCollapsibleBtnClose {
  right: -20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-right: 1px solid #c5c5c5;
}

.menu-collapsed {
  display: block;
  width: 100%;
  float: left;
  text-align: center;
  background-color: #2c3948;
  color: #7c838a;
  padding: 2px 0;
  margin: 0 auto 10px;
}

.ant-layout-header {
  background: #ffffff;
  padding: 0 0 10px 0;
}

.ant-layout-sider {
  background: #ffffff;
}

.home-menu {
  display: inline-block;
  width: 150px;
  height: 64px;
  font-size: 18px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.home-menu-icon {
  width: 33%;
  height: 64px;
  text-align: center;
  line-height: 64px;
  display: inline-block;
}
</style>
