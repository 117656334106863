<template>
  <div class="p-footer">
    <div class="p-footer-copyright">
      <div class="copyright">
        Copyright © 2014-2021
        <a class="p-link" target="_blank" href="https://pigwan.me"
          >🎉 version 1.0</a
        >

        <a class="p-link" target="_blank" href="https://pigwan.me"
          >🛠️ Powered by Pigwan</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLayout",
  components: {},
};
</script>

<style scoped>
.p-footer {
  text-align: center;
}

.p-link {
  margin-right: 5px;
}

.p-link,
.p-footer-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.p-link,
.p-version,
.p-create-time {
  display: inline-block;
  margin-left: 10px;
}
</style>
