<template>
  <div class="title">
    {{ menuTitle }}
  </div>
  <a-menu
    mode="inline"
    :inline-collapsed="collapsed"
    :selectedKeys="selectedSubKeys"
    :open-keys="openKeys"
  >
    <template v-for="item in routerMenus">
      <template v-if="item.children && item.children.length > 0">
        <a-sub-menu :key="item.meta.key">
          <template #title>{{ item.meta.title }}</template>
          <template
            style="padding-left: 10px"
            v-for="son in item.children"
            :key="son.meta.key"
          >
            <a-menu-item v-if="son.meta.menuShow === 1" @click="goTo(son)">
              {{ son.meta.title }}
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
      <template v-else>
        <a-menu-item :key="item.meta.key" @click="goTo(item)">{{
          item.meta.title
        }}</a-menu-item>
      </template>
    </template>
  </a-menu>
</template>

<script>
import { Menu, MenuItem, SubMenu } from "ant-design-vue/es";

import eventBus from "vue3-eventbus";
import router from "../router";

export default {
  name: "SubMenuLayout",
  data() {
    return {
      menuTitle: "",
      collapsed: false,
      routerMenus: [],
      selectedSubKeys: [],
      openKeys: [],
    };
  },
  components: {
    AMenu: Menu,
    AMenuItem: MenuItem,
    ASubMenu: SubMenu,
  },
  created() {
    this.getRouter(this.$route.matched[1]);
    this.selectedSubKeys = [];
    this.selectedSubKeys.push(this.$route.meta.key);
  },
  mounted() {
    eventBus.on("menu", (val) => {
      this.getRouter(val);
    });
    eventBus.on("selectedSubKeys", (val) => {
      const that = this;
      that.selectedSubKeys = [];
      that.selectedSubKeys.push(val);
    });
  },
  methods: {
    onOpenChange() {
      console.log("onOpenChange");
    },
    getRouter(route) {
      const that = this;
      that.routerMenus = [];
      let routerMenus = [];
      if (route.children.length > 0) {
        let subMenus = route.children;
        for (let i = 0; i < subMenus.length; i++) {
          if (
            subMenus[i].meta.menuType === "subMenu" &&
            subMenus[i].meta.menuShow === 1
          ) {
            routerMenus.push(subMenus[i]);
          }
        }
        that.routerMenus = routerMenus;
      }
      that.menuTitle = route.meta.title;
      if (that.routerMenus.length > 0) {
        eventBus.emit("subMenuWidth", 150);
      } else {
        eventBus.emit("subMenuWidth", 0);
      }
    },
    goTo(item) {
      const that = this;
      that.selectedSubKeys = [];
      that.selectedSubKeys.push(item.meta.key);
      router.push(item.path);
    },
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  height: 78px;
  line-height: 78px;
  text-align: center;
  font-size: 14px;
}
.p-menu-item {
  padding-left: 25px;
  height: 50px;
  line-height: 50px;
}
</style>
