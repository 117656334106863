<template>
  <div class="p-content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ContentLayout",
};
</script>

<style scoped>
.p-content {
  margin: 20px;
  background-color: #ffffff;
  /*min-height: 600px;*/
  /*max-height: calc(100vh - 193px);*/
  /*overflow: scroll;*/
}
</style>
